import { useEffect, useState } from "react"
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { confs } from "../../../modules/config";
import { useDropzone } from 'react-dropzone';
import {NotificationManager} from 'react-notifications';

const baseURL = confs.url

const ConvertorModal = ({isOpen, toggle}) => {
    const [file, setFile] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState(null)

    const handleConversion = async() => {
        if(!file) return
        
        setIsLoading(true)
      
        let data = new FormData()
        data.set("jwtToken", window.sessionStorage.getItem("token"))
        data.set("name",file.name)
        data.set("file", file, file.name)

       // Call REST API to convert the file
        try {
            const response = await fetch(baseURL + 'enterprise/convertPdfToDocx', {
                method: 'POST',
                body: data
            })
            console.log("handling response");
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${file.name.replace(/\.[^/.]+$/, '')}.docx`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error converting PDF to DOCX:', error);
        }
    }


  return(
    <Modal size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{'Convert PDF to DOCX'}</ModalHeader>
      <ModalBody>
        {/* {isLoading?  */}
            {/* <MegaLoaderSpinner />
            : */}
            <Dropzone
                setFile={setFile}
                acceptTypes="application/pdf"
            />
        {/* } */}
        {errors && <div className="alert alert-danger">{errors}</div>}
      </ModalBody>
      <ModalFooter>
        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggle()}>Sulje</a>
        <button className="small-white-button lighter-text wide-button" disabled={!file} color="primary" onClick={handleConversion}>Convert</button>
      </ModalFooter>
    </Modal>
  )
}


const MegaLoaderSpinner = () => {
    return (
        <Row>
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
                    <span className="sr-only">Converting...</span>
                </div>
            </Col>
        </Row>
    )
}

const Dropzone = ({setFile, acceptTypes='image/*, .doc, .docx, .pdf, .xls, .xlsx, .xlsm, .xlsb, .xltx, .txt, .ppt, .pptx, .pptm', showFiles=true }) => {
    const {
        getRootProps, 
        getInputProps, 
        isDragActive,
        acceptedFiles,
        fileRejections
    } = useDropzone({ 
        maxFiles:1,
        maxSize: 40000000,
        accept: acceptTypes,
    })

    const acceptedFileItems = acceptedFiles.map(file => ( 
        <p key={file.path}>
            {file.path} <span style={{ fontSize: '0.8rem' }}>- {file.size} bytes</span>
        </p>
    ))
    
    useEffect(() => {
        if(acceptedFiles && acceptedFiles.length > 0){
            let acceptedFile = acceptedFiles[0]
            
            setFile(acceptedFile)
            NotificationManager.success("Tiedostomuoto hyväksytty", "Hyväksytty", 2000);
        }
        if (fileRejections && fileRejections.length > 0) {
            let rejectedFile = fileRejections[0]
            rejectedFile.errors.map(e => {
                if(e.code === "file-too-large"){
                    NotificationManager.error("Tiedosto liian suuri", "Virhe", 4000);
                }
                if(e.code === "file-invalid-type"){
                    NotificationManager.error("Tiedostomuoto ei ole hyväksytty", "Virhe", 4000);
                }
            })
        }
    },[acceptedFiles, fileRejections])



    const clearZone = () => {
        
    }

    return (
        <div className="drop-container">
            <div {...getRootProps({className:"drop-zone"})}>
                <input {...getInputProps()} /> 
                {isDragActive ?
                    <>
                        <i style={{ fontSize: "3rem", opacity: '0.8' }}  className="far fa-folder-open"></i>
                        <p>Pudota tiedosto tänne tai avaa tiedoston valinta klikkaamalla </p>
                    </>
                    :
                    (showFiles && acceptedFiles && acceptedFileItems.length > 0?
                        <>
                            <i style={{ fontSize: "3rem", opacity: '0.8' }} className="far fa-file-alt"></i>
                            {acceptedFileItems}
                        </>
                        :
                        <>
                            <i style={{ fontSize: "3rem", opacity: '0.8' }} className="far fa-folder"></i>
                            <p> Pudota tiedosto tänne tai avaa tiedoston valinta klikkaamalla</p>
                        </>
                    )
                } 
            </div>
           {fileRejections && fileRejections[0] && fileRejections[0].errors.map(e => {
                if(e.code === "file-invalid-type"){
                    return(
                        <>
                            <p>Hyväksytyt tiedostomuodot:</p>
                            <p>{acceptTypes}</p>
                        </>
                    )
                }
                if(e.code === "file-too-large"){
                    return(
                        <p>Tiedoston maksimi koko on 40Mb </p>
                    )
                }
            })}
        </div>
    )
}

export default ConvertorModal