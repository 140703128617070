import React, { Component, Fragment, useEffect, useState } from 'react'
import { Container, Row, Col, Label, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap'
import SurveySummary from '../Kartoitus/Sender/modules/surveySummary'
import SurveyList from '../Kartoitus/Sender/modules/surveyList'
import {Tasks, Answers, Risks } from '../Kartoitus/Sender/modules/taskList'
import TaskCardModal from '../Reports/Dashboard/TaskCards/TaskCardsMainBoard/TaskCardModal'
import { Scrollbars } from 'react-custom-scrollbars'
import ActiveBusinessList from '../Clients/subComponents/ActiveBusinessList'
import { Plans, Projects, Contacts, ActivatedSurveysList, ProjectTimeline } from '../../Utilities/horizontal-scroller/card-lists/Lists'
import NewsModal from '../../Utilities/Modals/NewsModal'
import BoxSearchBar from '../../Utilities/BoxSearchBar/BoxSearchBar'
import Select from 'react-select';
import LatestAnswerRow from '../Reports/subComponents/LatestAnswerRow'
import { executeRequest } from '../../Utilities/requestHandler/dataHandler';
import { NotificationManager } from 'react-notifications';
import { ListSelectionModal } from './subComponents/ListSelectionModal'
import {DashboardGuide} from '../../Utilities/GuideTour/DashboardGuide';
import { CreateNewModal } from './subComponents/CreateNewModal'
import { Favourites } from '../../Utilities/Favourites/Favourites'
import { isTemplateMiddle } from 'typescript'
import { DashboardGantt } from './subComponents/DashboardGantt'
import { DashboardSearch } from './subComponents/DashboardSearch'
import * as ActionCreator from '../../store/actions/Login/ActionCreator'
import { useDispatch, useSelector } from 'react-redux'
import MultiSelect from "@kenshooui/react-multi-select";
import ConvertorModal from './subComponents/ConvertorModal' 

const activeListTypes = {
  forms: 'cards',
  projects: 'cards',
  clients: 'cards',
  tasks: 'cards',
  answers: 'cards',
  surveys: 'cards',
  risks: 'cards'
}

const initialListSelection = [
  {name: 'gantt', visible: true, label: 'ProjGantt', id: 11},
  {name: 'project-timeline', visible: false, label: 'Projektitehtävät aikajanalla', id: 88},
  {name: 'plans', visible: true, label: 'Lomakkeet', id: 11},
  {name: 'projects', visible: true, label: 'Projektit', id: 22},
  {name: 'clients', visible: true, label: 'Aktiiviset asiakkaat', id: 33},
  {name: 'activeSurveys', visible: true, label: 'Aktiiviset kartoitukset', id: 44},
  {name: 'answers', visible : true, label: 'Viimeisimmät vastaukset', id: 55},
  {name: 'tasks', visible: false, label: 'Tarkistuspisteet', id: 66},
  {name: 'risks', visible: false, label: 'Riskit', id: 77}
]

export default class DashboardMain extends Component {
  constructor() {
    super()
    this.state = {
      taskModalOpen: false,
      selectedTask: null,
      surveyFilter: '',
      taskFilter: '',
      businessFilter: '',
      tourOpen: false,
      infoModal: false,
      steps: [],
      modalIsOpen: false,
      targetBusiness: {},
      clientList:{},
      contactsVisible: false,
      activeListTypes: activeListTypes,
      listSelection: initialListSelection,
      listSelectionModal: false,
      convertorModal:false,
      filter: "",
      search:'',
      settingsDropdown: false,
      topBarInView: true,
      firstLoginModal: false
    }
    this.searchRef = React.createRef();
  }
  
 toggleTaskModal = () => {
    this.setState(prevState => ({
      taskModalOpen: !prevState.taskModalOpen
    }))
  }
  componentDidMount = () => {
    const getClients = async() => {
      const response = await executeRequest('sender/get/getCompanies',{})
      if(response && response.businessList){
          this.setState({clientList :response.businessList})
      }
    }
    getClients()
    this.getStoredTypes()
    this.getStoredListSettings()
    // document.documentElement.style.setProperty('--highlight-color', '#eb7404');
    window.addEventListener('scroll', this.handleScroll);
    if(!this.props.firstLoginDone){
      this.setState({firstLoginModal: true})
    }
  }
  
  componentWillUnmount = () => {
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {
    let scrollTop = event.srcElement.body.scrollTop
    let search = this.searchRef
    let scroll =  window.scrollY

    if(scroll !== null){
      if (scroll && scroll> 96 && this.state.topBarInView) {
        this.setState({topBarInView: false})
      } else if (scroll < 96) {
        this.setState({topBarInView: true})
      }
    }
  }

  getStoredTypes = () => {
    if(window.localStorage.getItem('activeListTypes') !== null){
      let types = JSON.parse(window.localStorage.getItem('activeListTypes'))
      this.setState({activeListTypes: types})
    }
  }
  
  handleActiveTypeChange = (id, type) => {
    this.setState({activeListTypes:{...this.state.activeListTypes, [id]: type}}, () => this.updateStorage())
  }

  updateStorage = () => {
    const types = {...this.state.activeListTypes}
    window.localStorage.setItem('activeListTypes', JSON.stringify(types)) 
  }

  updateListSelectionStorage = () => {
    const selection = [...this.state.listSelection]
    window.localStorage.setItem('listSelection', JSON.stringify(selection)) 
  }

  getStoredListSettings = () => {
    if(window.localStorage.getItem('listSelection') !== null){
      let selection = window.localStorage.getItem('listSelection')
      let test = JSON.parse(selection)
      
      if(test.length > 0 && test.length === this.state.listSelection.length && test[0] !== undefined && test[0].name !== undefined){
         this.setState({listSelection: JSON.parse(selection)})
      }
    }
  }

  handleListSelection = (params) => {
    const {id, value} = params

    let list = this.state.listSelection

    list.map((item, index) => {
      if(item.name === id){
        item.visible = value
        return item
      }
    })

    this.setState({listSelection: [...list]})}

  onTaskSelect = (task) => {
    this.setState({ selectedTask: task, taskModalOpen: true })
  }

  updateTaskStatus = (params) => {
    this.toggleTaskModal()
    this.props.updateTask(params)
  }

  setFilter = (event) => {
    const name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  selectLatestAnswerReport = async ( obj ) => {
    const { getReport, getReportTree, addReportFilter, filterTypes, activeFilters } = this.props
    
    const params = {
        reports: [
            {
                reportId: obj.sentSurveyId
            }
        ],
        answerSessionIds: [
            obj.answerSessionId
        ]
    }
  
    let filterType = JSON.parse(JSON.stringify( filterTypes.answerSession ) )
    filterType.answerSessionIds = [ obj.answerSessionId ]
  
    addReportFilter( filterType, activeFilters )
  
    getReport(params)
  
    if(obj.businessId !== 'anon') {
        await getReportTree({businessId: obj.businessId})
        this.props.history.push('/raportit/yritys/raportti')
    } else {
        this.props.storeBusiness( { name: 'Ei kohdeyritystä' } )
        this.props.history.push('/raportit/kohdistamaton/raportti')
    }
  }

  newProject = () => {
    if(this.state.targetBusiness.label){
      this.props.history.push({
          pathname:'projektit/hallitse',
          state: {
              targetBusiness:this.state.targetBusiness
          }
      })
    } else {
      NotificationManager.error("Valitse kohdeyritys, jolle projekti luodaan", "", 3000);
    }
  }

  setTargetBusiness = (selected) => { 
    this.setState({targetBusiness: selected})
  }

  toggleModal = () => {
    this.setState({modalIsOpen:!this.state.modalIsOpen})
  } 

  showContacts = (visible) => {
    this.setState({contactsVisible: visible})
  }

  toggleListSelectionModal = () => {
    this.setState({listSelectionModal: !this.state.listSelectionModal})
  }
  toggleSettingsDropdown = () => {
    this.setState({settingsDropdown: !this.state.settingsDropdown})
  }

  handleSearch = (searchValue) => {
    this.setState({ filter: searchValue })
    this.updateSearch(searchValue)
  }

  updateSearch = (value) => {
    this.setState({search: value})
  }

  openVideoConnection = () => {
    window.open(
      "https://main.d3ktky0fiy06np.amplifyapp.com/",
      '_blank'
    )
  }

  toggleInfoModal = () => {
    this.setState({infoModal: !this.state.infoModal})
  }

  toggleFirstLoginModal = () => {
    this.setState({firstLoginModal: !this.state.firstLoginModal})
  }

  toggleTour = () => {
    this.setState({tourOpen: !this.state.tourOpen})
  }

  toggleConvertorModal = () => {
    this.setState({convertorModal: !this.state.convertorModal})
  }
  render() {
    const { taskModalOpen, selectedTask, modalIsOpen, listSelection } = this.state
    const { selectReportFromTask, modulesInUse, onPlanEdit, news, showNews, goToReport, listOrder } = this.props
   
    return (
      <Container fluid id="yhteenveto-tourId "  className="dashboard-main">
     
        {showNews && <NewsModal showNews={!news} />}
        {(modulesInUse.taskManagement && taskModalOpen) && <TaskCardModal
          isOpen={taskModalOpen}
          toggler={this.toggleTaskModal}
          task={selectedTask}
          updateTaskStatus={this.updateTaskStatus}
          selectReport={selectReportFromTask}
        />
        }
        <ListSelectionModal
          isOpen={this.state.listSelectionModal}
          listSelection={this.state.listSelection}
          updateSelection={this.updateListSelectionStorage}
          modulesInUse={modulesInUse}
          handleListSelection={this.handleListSelection}
          toggle={this.toggleListSelectionModal}
        /> 
        <FirstLoginModal 
          firstLoginDone={this.props.firstLoginDone}
          isOpen={this.state.firstLoginModal}
          toggleTour={this.toggleTour}
          toggle={this.toggleFirstLoginModal} 
          handleFirstLoginDone={this.props.handleFirstLoginDone}
        />
        <InfoModal 
          isOpen={this.state.infoModal}
          toggle={this.toggleInfoModal} 
          adminUser={this.props.adminUser}
          enterpriseDownloads={this.props.enterpriseDownloads}
          updatePlans={false}
        />
        <ConvertorModal
          isOpen={this.state.convertorModal}
          toggle={this.toggleConvertorModal}
        />
        {!this.state.topBarInView && <DashboardSearch search={this.state.filter} setSearch={this.handleSearch}/>}
        <Row style={{ position: 'relative', marginTop:'1em', marginRight: '0.25em'}}>
          <Col xs="10">
            <Row>
              <Col>
                <CreateNewModal history={this.props.history} clientList={this.state.clientList}/>
              </Col>
              <Col xs="10 " style={{position:'sticky !important'}}>
                <div id="searchBarId" ref={this.searchRef} >
                  <Input type="search" id="search-tourId" className="search-bar-open" placeholder={"Hae lomakkeita, projekteja yms.."} value={this.state.filter} onChange={(event) => this.handleSearch(event.target.value)} />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs="2">
            <Row>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ width: ''}}></div>
                <Favourites history={this.props.history}/>
                <div id="dashboard-options-tourId" className="" title="Yhteenvedon asetukset" style={{ fontSize: '1.5rem', width: '2em'}}>
                    <Dropdown isOpen={this.state.settingsDropdown} className="" style={{ cursor: 'pointer' }} onClick={(event) => event.stopPropagation()} toggle={() => this.toggleSettingsDropdown()} >
                        <DropdownToggle tag="div">
                            <div className="dashboard-button" title="Yhteenvedon asetukset" onClick={() => this.toggleSettingsDropdown()} style={{}}><i className="fa fa-cog"></i></div>
                        </DropdownToggle>
                        <DropdownMenu >
                            <DropdownItem onClick={() => this.toggleListSelectionModal()}>Listojen asetukset</DropdownItem>
                            {modulesInUse.documentConvertion && <DropdownItem onClick={() => this.toggleConvertorModal()}>Convert PDF to DOCX</DropdownItem>}
                        </DropdownMenu>
                    </Dropdown>
                </div>
                {modulesInUse.videoBeta && 
                  <div className="dashboard-button" style={{ width: '2.8em' }}>
                    <i style={{ fontSize: '1.5rem', color:'#6ba53f', cursor: 'pointer',  }} className="fas fa-video favourite-selected" title="Siirry videokeskusteluihin" onClick={() => this.openVideoConnection()}></i> 
                  </div>
                }
                <DashboardGuide toggleTour={this.state.tourOpen} adminUser={this.props.adminUser}/>
                <div id="yhteenveto-info" className="tour-button dashboard-button" style={{ width: '2em' }} onClick={() => this.toggleInfoModal()}><i className="fas fa-info-circle"></i></div>
              </div>
            </Row>
          </Col>
          {/* <SurveySummary sentSurveys={this.props.sentSurveys} openListSelectionModal={this.toggleListSelectionModal} dashboardSummary={this.props.dashboardSummary} createNew={this.createNew} history={this.props.history} clientList={this.state.clientList}/> */}
        </Row> 
        <Row style={{ display: 'flex', justifyContent: 'space-between'}}>
          {this.state.listSelection && this.state.listSelection.map((item, key)=>{
            switch (item.name){
              case 'contacts':
                return (
                    <Fragment key={key}>
                      {this.state.contactsVisible &&
                        <Contacts showAddNewOnList={false} newContact={false} search={this.state.search}/>
                      }
                    </Fragment>
                )
              case 'project-timeline':
                return(
                  <Fragment key={key}>
                    {modulesInUse.projectManagement&& modulesInUse.ganttEnabled && modulesInUse.betaTesting && item.visible &&
                      <ProjectTimeline search={this.state.search} history={this.props.history}/>
                    }
                  </Fragment>
                )
              case 'plans':
                return (
                  <Fragment key={key}>
                    {modulesInUse.planManagement && item.visible &&
                      <Plans goToPlan={onPlanEdit} newPlan={this.props.goToNewPlan} activeType={this.state.activeListTypes.forms} toggleType={this.handleActiveTypeChange} search={this.state.search}/>
                    }
                  </Fragment>
                )
              case 'projects':
                return (
                  <Fragment key={key}>
                    {modulesInUse.projectManagement && item.visible &&
                      <>
                        <Modal isOpen={modalIsOpen} toggle={() => this.toggleModal()} >
                          <ModalHeader toggle={() => this.toggleModal()} ><span>Valitse kohdeyritys, jolle projekti luodaan</span></ModalHeader>
                          <ModalBody>
                            <Label>Kohdeyritys</Label>
                            <Select
                              value={this.state.targetBusiness}
                              onChange={(selected) => this.setTargetBusiness(selected)}
                              options={this.state.clientList}
                              placeholder="Valitse kohdeyritys.."
                              isDisabled={false}
                              noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                              className="companyTargets"
                              classNamePrefix="select"
                            />
                          </ModalBody>
                          <ModalFooter>
                            <button className="small-white-button wide-button" style={{ width: '100%' }} onClick={() => this.newProject()}>Jatka</button>
                          </ModalFooter>
                        </Modal>
                        <Projects history={this.props.history} newProject={this.toggleModal} activeType={this.state.activeListTypes.projects} toggleType={this.handleActiveTypeChange} search={this.state.search}  modulesInUse={modulesInUse} dashboardMain={true}/>
                      </>
                    }
                  </Fragment>
                )
              case 'clients':
                return (
                  <Fragment key={key}>
                    {modulesInUse.clientManagement && item.visible &&
                      <ActiveBusinessList activeBusinessList={this.props.activeBusinessList} filter={this.state.businessFilter} setFilter={this.setFilter} selectBusiness={this.props.selectBusiness} activeType={this.state.activeListTypes.clients} toggleType={this.handleActiveTypeChange} search={this.state.search}/>            
                    }
                  </Fragment>
                )
              case 'activeSurveys':
                return (
                  <Fragment key={key}>
                    {modulesInUse.surveyManagement && item.visible &&
                      <ActivatedSurveysList activeType={this.state.activeListTypes.surveys} toggleType={this.handleActiveTypeChange} history={this.props.history} search={this.state.search}/>
                    }
                  </Fragment>
                )
              case 'answers':
                return (
                  <Fragment key={key}>
                    {modulesInUse.surveyManagement && item.visible &&
                      <Answers answers={ this.props.latestAnswers } selectAnswer={ this.selectLatestAnswerReport } activeType={this.state.activeListTypes.answers} toggleType={this.handleActiveTypeChange} search={this.state.search}/>
                    } 
                  </Fragment>
                )
              case 'tasks':
                return (
                  <Fragment key={key}>
                    {modulesInUse.taskManagement && item.visible &&
                      <Tasks sentSurveys={this.props.sentSurveys} onTaskSelect={this.onTaskSelect} filter={this.state.taskFilter} setFilter={this.setFilter} activeType={this.state.activeListTypes.tasks} toggleType={this.handleActiveTypeChange} search={this.state.search}/>   
                    }
                  </Fragment>
                )
              case 'risks':
                return(
                  <Fragment key={key}>
                    {modulesInUse.riskManagement && item.visible &&
                      <Risks activeType={this.state.activeListTypes.risks} toggleType={this.handleActiveTypeChange} goToPlan={onPlanEdit} goToReport={goToReport} search={this.state.search}/>
                    }
                  </Fragment>
                )
              default:
                return(<Fragment key={key}></Fragment>)
            }
          })}
        </Row>
      </Container>
    )
  }
}


const EmptyBox = (props) => {
  return (
    <Col md={{ size: '6' }} >
      <div className="company-box">
        <div className="box-header">
          <p><b>{props.title}</b></p>
        </div>
        <Scrollbars
          renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
          renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
          style={{ width: "100%", height: '500px', marginTop: '0.5rem' }} >
          <MegaLoaderSpinner />
        </Scrollbars>

      </div>
    </Col>
  )
}

const MegaLoaderSpinner = () => {
  return (
    <Row>
      <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </Col>
    </Row>
  )
}

const steps = (modules) => {
  return (
    [
      {
        selector: '#yhteistyo',
        content: 'Four loko deep v bespoke, cronut pinterest brooklyn williamsburg master cleanse lomo fingerstache heirloom. XOXO farm-to-table air plant four dollar toast woke pork belly. Retro gluten-free tbh mlkshk. Normcore kinfolk ramps chicharrones bitters. Franzen beard four dollar toast raclette. Poke paleo hashtag prism literally.'
      },
      {
        selector: '#tiedot',
        content: 'ctivated charcoal snackwave craft beer locavore before they sold out four dollar toast. Blue bottle salvia street art la croix locavore tilde keffiyeh hexagon. Hexagon cronut tacos organic. Narwhal humblebrag normcore synth glossier cronut tousled kickstarter mumblecore live-edge thundercats cardigan cold-pressed 8-bit tumblr. '
      },

    ]
  )
}

const FirstLoginModal = ({isOpen, toggle, firstLoginDone, toggleTour, handleFirstLoginDone}) => {

  const closeandCheckFirstLoginDone = () => {
    if(!firstLoginDone){
      setFirstLoginDone()
      toggleTour()
    }
    toggle()
  }
  const setFirstLoginDone = async() => {
    let payload = {}
    handleFirstLoginDone(payload)
    
  }

  return(
    <Modal size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Tervetuloa EverBetter-palveluun</ModalHeader>
      <ModalBody>
        <div style={{position: "relative", overflow: "hidden", paddingTop: "56.25%"}}>
          <iframe src="https://share.synthesia.io/embeds/videos/a27828f0-3a06-42d3-86ff-58b34076bb85" loading="lazy" title="Synthesia video player - Tervetuloa EverBetter-palveluun" allow="encrypted-media; fullscreen;" style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0, border: "none", padding: 0, margin: "0", overflow:"hidden"}}></iframe>
        </div>
      </ModalBody>
      <ModalFooter>
        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => closeandCheckFirstLoginDone()}>Sulje</a>
      </ModalFooter>
    </Modal>
  )
}

const infoListTest = [
  {
    label: "EverBetter Käyttöohjeet",
    value: "https://app.everbetter.fi/plan/6036454f0a68be6215c74278"
  }
]

const downloadTestList = [
  { id: "6036454f0a68be6215c74277", label:"EverBetter Käyttöohjeet", group:'Lomake'},
  { id: "6036454f0a68be6215c74278", label:"EverBetter Kt", group:'Lomake'},
  { id: "6036454f0a68be6215c74279", label:"EverBetter Kartoitus", group:'Kartoitus'}

]

const customMessages = {
  messages: {
      searchPlaceholder: "Etsi...",
      noItemsMessage: "Valitse ladattavat dokumentit",
      noneSelectedMessage: "Valintoja ei ole",
      selectedMessage: "valittuna",
      selectAllMessage: "Valitse kaikki",
      clearAllMessage: "Tyhjennä valinnat",
      disabledItemsTooltip: "You can only select 1 file"
  }
};

const InfoModal = ({isOpen, toggle, adminUser, enterpriseDownloads, updatePlans}) => {
  const [type, setType] = useState("info")
  const [infoList, setInfoList] = useState(infoListTest)
  const [selectedDownloads, setSelectedDownloads] = useState([])
  const [loading, setLoading] = useState(false)
  const [downloaded, setDownloaded] = useState(false)

  const downloadSelected = async() => {
    setLoading(true)
    let payload = {
      selectedDownloads: selectedDownloads
    }
    const response = await executeRequest("downloadForEnterprise",payload)
    if(response && response.success){
      NotificationManager.success("Ladattu onnistuneesti", "",3000)
      setDownloaded(true)
    } else {
      NotificationManager.error("Virhe ladattaessa tiedostoja, ole hyvä ja yritä uudelleen.", "Virhe", 3000)
    }
    setLoading(false)
  }
  return(
    <Modal size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{"Ohjeet" + (adminUser? " ja lataukset":"")}</ModalHeader>
      <ModalBody>
        <div style={{padding:'1em'}}>
          {adminUser &&
            <div style={{display:'flex', marginBottom:'2em', justifyContent:'space-between'}}>
              <button className={"small-white-button wide-button " + (type === "info" && "active-button")} style={{ width: '48%', fontWeight:'bold' }} onClick={() => setType("info")}>Ohjeet</button>
              <button className={"small-white-button wide-button " +(type === "downloads" && "active-button")} style={{ width: '48%', fontWeight:'bold' }} onClick={() => setType("downloads")}>Lataukset</button>
            </div>
          }
          {type === "info" &&
            <div>
              <div style={{ display: 'flex', justifyContent:'center', width:'100%'}}>
                <div style={{position: "relative", height:'20em', width: '35em'}}>
                  <iframe src="https://share.synthesia.io/embeds/videos/a27828f0-3a06-42d3-86ff-58b34076bb85" loading="lazy" title="Synthesia video player - Tervetuloa EverBetter-palveluun" allow="encrypted-media; fullscreen;" style={{position: "absolute", width: "100%", height: '100%', top: 0, left: 0, border: "none", padding: 0, margin: "0"}}></iframe>
                </div>
              </div>
              <div style={{ marginTop: '1em'}}>
                <Col>
                  {infoList && infoList.length > 0 && infoList.map((info,key) => {
                    return(
                      <Row key={key}>
                        <a href={info.value} target="_blank">{info.label}</a>
                      </Row>
                    )
                  })}
                </Col>
              </div>
            </div>
          }
          {type === "downloads" &&
            <>
              {loading?
                <MegaLoaderSpinner/>
                :
                <div>
                    {downloaded && <div><i className="fas fa-info-circle"></i><p>Ladatut tiedostot tulevat näkyviin niiden tyyppiä vastaaville välilehdille (kts. sivuvalikko Lomakkeet, Kartoitukset)</p></div>}
                    <MultiSelect
                      messages={customMessages.messages}
                      items={enterpriseDownloads}
                      selectedItems={selectedDownloads}
                      onChange={setSelectedDownloads}
                      wrapperClassName="multi-selector"
                      withGrouping
                      height={1600}
                    />
                    <button className={"small-white-button wide-button active-button"} style={{ width: '100%', fontWeight:'bold', marginTop:'1em' }} onClick={() => downloadSelected()}>Lataa valitut</button>
                </div>
              }
            </>
          }
        </div>
      </ModalBody>
      <ModalFooter>
        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggle()}>Sulje</a>
      </ModalFooter>
    </Modal>
  )
}

